export var FeatureFlagsKeysEnum;
(function (FeatureFlagsKeysEnum) {
    FeatureFlagsKeysEnum["IS_TEMPLATE_STORE_ENABLED"] = "IS_TEMPLATE_STORE_ENABLED";
    FeatureFlagsKeysEnum["IS_TOPIC_NOTIFICATION_ENABLED"] = "IS_TOPIC_NOTIFICATION_ENABLED";
    FeatureFlagsKeysEnum["IS_MULTI_PROVIDER_CONFIGURATION_ENABLED"] = "IS_MULTI_PROVIDER_CONFIGURATION_ENABLED";
    FeatureFlagsKeysEnum["IS_MULTI_TENANCY_ENABLED"] = "IS_MULTI_TENANCY_ENABLED";
    FeatureFlagsKeysEnum["IS_USE_MERGED_DIGEST_ID"] = "IS_USE_MERGED_DIGEST_ID_ENABLED";
})(FeatureFlagsKeysEnum || (FeatureFlagsKeysEnum = {}));
export var SystemCriticalFlagsEnum;
(function (SystemCriticalFlagsEnum) {
    SystemCriticalFlagsEnum["IS_IN_MEMORY_CLUSTER_MODE_ENABLED"] = "IS_IN_MEMORY_CLUSTER_MODE_ENABLED";
})(SystemCriticalFlagsEnum || (SystemCriticalFlagsEnum = {}));
